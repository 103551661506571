import { addClass, hasClass, isBrowser, removeClass, remToPx } from "@frontendUtils/helpers";
import BrowserStorage, { StorageType } from "@frontendUtils/browser-storage";
import { IGNORE_UNSUPPORTED_BROWSER_WARNING } from "@frontendJs/util/browser-storage";
import { rawSizes } from "@frontendConstants/rawSizes";
import {togglebackdropBlur} from "@apps/Shared/Utils/Utils";


const storage = isBrowser ? new BrowserStorage(StorageType.LOCAL) : null
const headerTopHeight = '5rem'
const headerBottomHeight = '3.75rem'

const UNSUPPORTED_BROWSER_WARNING_HEIGHT = 212

const HEADER_TOP_HEIGHT = remToPx(headerTopHeight)
const HEADER_BOTTOM_HEIGHT = remToPx(headerBottomHeight)

const STICKY_READY_CSS_CLASS = 'header-sticky-ready'
const STICKY_READY_CSS_CLASS_HIDE = `${STICKY_READY_CSS_CLASS}--hide`
const STICKY_READY_CSS_CLASS_ANIMATE = `${STICKY_READY_CSS_CLASS}--animate`
const STICKY_BY_DEFAULT_CLASSES = `${STICKY_READY_CSS_CLASS} header-sticky--default`
let lastScroll = 0

function clickIgnoreUnsupportedBrowser(unsupportedBrowserElement: HTMLElement) {
    storage && storage.setItem(IGNORE_UNSUPPORTED_BROWSER_WARNING, 'true');
    hideUnsupportedBrowserWarning(unsupportedBrowserElement);
}

function hideUnsupportedBrowserWarning(unsupportedBrowserElement: HTMLElement) {
    unsupportedBrowserElement.style.display = 'none';
}

function setSticky(mobile: boolean, hideSticky = false, header: HTMLElement) {
    const animate = hasClass(header, STICKY_READY_CSS_CLASS)

    removeClass(header, STICKY_READY_CSS_CLASS)
    removeClass(header, STICKY_READY_CSS_CLASS_HIDE)
    removeClass(header, STICKY_READY_CSS_CLASS_ANIMATE)

    if (mobile) {
        document.body.setAttribute(
            'style',
            `padding-top: ${HEADER_BOTTOM_HEIGHT}px`
        )
    } else {
        document.body.setAttribute('style', `padding-top: 0`)
    }

    if (animate) {
        addClass(header, STICKY_READY_CSS_CLASS_ANIMATE)
    }

    addClass(header, STICKY_READY_CSS_CLASS)

    if (hideSticky) {
        addClass(header, STICKY_READY_CSS_CLASS_HIDE)
    }
}

function setNotSticky(header: HTMLElement) {
    document.body.removeAttribute('style')

    removeClass(header, STICKY_READY_CSS_CLASS)
    removeClass(header, STICKY_READY_CSS_CLASS_HIDE)
    removeClass(header, STICKY_READY_CSS_CLASS_ANIMATE)
}

function handleScroll(isUnsupportedBrowser: boolean, header: HTMLElement) {
    const smallDevice = window.innerWidth < rawSizes.xl
    const scroll = window.scrollY
    const isScrollingDown = scroll >= lastScroll
    const hasPassedMobileScrollThreshold =
        scroll >
        HEADER_TOP_HEIGHT +
        (!smallDevice && isUnsupportedBrowser
            ? UNSUPPORTED_BROWSER_WARNING_HEIGHT
            : 0)
    const hasPassedDesktopScrollThreshold =
        scroll >
        HEADER_TOP_HEIGHT +
        HEADER_BOTTOM_HEIGHT +
        (isUnsupportedBrowser ? UNSUPPORTED_BROWSER_WARNING_HEIGHT : 0)
    const megaMenuOpen = hasClass(document.body, 'backdrop-blur')

    lastScroll = scroll

    let fixedHeader = false;

    if (isScrollingDown && hasPassedMobileScrollThreshold) {
        setDisplaySearchModal(false);
    }

    if (megaMenuOpen) {
        if (!fixedHeader && !hasPassedMobileScrollThreshold) {
            return setNotSticky(header)
        }

        return // Do nothing
    }

    if (fixedHeader || smallDevice) {
        const hideSticky = hasPassedMobileScrollThreshold && isScrollingDown
        return setSticky(smallDevice, hideSticky, header)
    } else {
        if (hasPassedDesktopScrollThreshold) {
            const hideSticky = isScrollingDown

            return setSticky(smallDevice, hideSticky, header)
        } else if (hasPassedMobileScrollThreshold) {
            return // Do nothing
        }
    }

    return setNotSticky(header)
}

function toggleDisplaySearchModal() {
    const searchModal = document.querySelector('.search-modal') as HTMLElement;
    searchModal?.classList?.toggle('hide');
    togglebackdropBlur(!searchModal?.classList?.contains('hide'));
}

function setDisplaySearchModal(displaySearchModal: boolean) {
    const searchModal = document.querySelector('.search-modal') as HTMLElement;
    if (displaySearchModal) {
        searchModal?.classList.remove('hide');
        togglebackdropBlur(true);
    } else {
        searchModal?.classList.add('hide');
        togglebackdropBlur(false);
    }
}
function searchModalListener(event: any) {
    const isSearchBtn =
        event.target &&
        event.target.classList &&
        event.target.classList.contains('icon-search')

    const headerWrapper = document.querySelector('.header__header-inner') as HTMLElement;
    const isSubNavigationOpen = document.querySelector('.sub-navigation-bar__nav-group-second.active')
    if (!headerWrapper || headerWrapper.contains(event.target) || isSearchBtn || isSubNavigationOpen) {
        return
    }
    setDisplaySearchModal(false)
}

document.addEventListener('mousedown', searchModalListener)
document.addEventListener('touchstart', searchModalListener)

document.addEventListener('DOMContentLoaded', function () {
    const unsupportedBrowser = document.querySelectorAll('.header__unsupported-browser');

    if (unsupportedBrowser.length) {
        let ignoreWarning = storage && storage.getItem(IGNORE_UNSUPPORTED_BROWSER_WARNING) === 'true'

        if (ignoreWarning) {
            hideUnsupportedBrowserWarning(unsupportedBrowser[0] as HTMLElement);
        }

        let ignoreButton = unsupportedBrowser[0].querySelector('.button');
        ignoreButton?.addEventListener('click', () => clickIgnoreUnsupportedBrowser(unsupportedBrowser[0] as HTMLElement));
    }
    const header = document.getElementsByClassName(
        'header-sticky'
    )[0] as HTMLElement
    
    window.addEventListener('scroll', () => handleScroll(unsupportedBrowser.length > 0, header), {passive: true})
    window.addEventListener('resize', () => handleScroll(unsupportedBrowser.length > 0, header), {passive: true})

    const searchButtons = document.querySelectorAll('.search-button > button');
    searchButtons.forEach((button) => {
        button.addEventListener('click', (e: Event) => {
            toggleDisplaySearchModal();
        });
    });
});